import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { itemsFetchData, itemsUpdateData } from '../actions/items';
import {mdApi} from "../index";
import { countersFetchData } from '../actions/counters';
import { MOBILE } from '../helpers/constants/HeaderConstants';

import '../sass/Tools.css';

class Pdd extends Component {
    componentDidMount() {
      window.page = 2;
      const _this = this;

      this.props.fetchData(mdApi.apiUri() + '/project_in_actions/videos/1/pdd');
      this.props.fetchCounters(mdApi.apiUri() + '/project_in_actions/video_counters/');

      let timer;

      window.onscroll = function() {
          if(!document.getElementsByClassName('truba-wrapper').length)
            return;

          let scrollHeight, totalHeight;
          scrollHeight = document.body.scrollHeight - 100;
          totalHeight = window.scrollY + window.innerHeight;

          if(totalHeight >= scrollHeight && window.page <= Math.ceil(_this.props.counters.pdd/12))
          {
           if ( timer ) clearTimeout(timer);
           timer = setTimeout( () => {
              _this.props.updateData(mdApi.apiUri() + '/project_in_actions/videos/' + window.page + '/pdd');
                ++window.page;
            }, 1000);
          }
      }
    }

    render() {
    if(MOBILE)
        this.props.history.push('/');

      let items = [];

      if(this.props.items.length !== 0)
      {// eslint-disable-next-line
        Object.keys(this.props.items).reverse().map((item) => {
            items.push(<li className="items-grid__item" key={item}>
                <Link className="items-grid__item_link" to={`/pdd/${item}`}>
                    <img src={this.props.items[item].img} alt={this.props.items[item].name}/>
                    <span className="items-name">{this.props.items[item].name}</span>
                    <span className="items-date">{this.props.items[item].date}</span>
                    <span className="items-views">{this.props.items[item].views}</span>
                </Link>
            </li>)
        });
      }

      return (
          <div>
            <div className="content-wrapper wide">
                <div className="content-truba_tool">
                    <div className="content-text">
                         <div className="content-text__upper">
                             Здесь хранятся видеозаписи ДТП, инцидентов и правонарушений, попавших в поле зрения камер проекта  «Мой дом». <br/>Раздел постоянно обновляется.
                         </div>
                         <div className="content-text__bottom">
                             <Link to="/videos">Все</Link>
                             <Link className="active" to="/videos/dtp">ДТП
                               <span>{this.props.counters.dtp}</span>
                             </Link>
                             <Link to="/videos/vandal">Вандализм
                                 <span>{this.props.counters.vandal}</span>
                             </Link>
                             <Link to="/videos/theft">Кражи
                                 <span>{this.props.counters.theft}</span>
                             </Link>
                             <Link to="/videos/fight">Драки
                                 <span>{this.props.counters.fight}</span>
                             </Link>
                             <Link to="/videos/other">Прочее
                                 <span>{this.props.counters.other}</span>
                             </Link>
                        </div>
                    </div>
                  </div>
                  <div className="content-dtp__linker">
                      <Link to="/videos/dtp">Все</Link>
                      <Link to="/videos/dtpin">ДТП во дворах
                          <span>{this.props.counters.dtpin}</span>
                      </Link>
                      <Link to="/videos/dtpout">ДТП на перекрестках
                          <span>{this.props.counters.dtpout}</span>
                      </Link>
                      <Link className="active" to="/pdd">Разбор ДТП
                          <span>{this.props.counters.pdd}</span>
                      </Link>
                  </div>
                  <div className="truba-wrapper">
                  {this.props.items.length !== 0 &&
                  <div>
                    <ul className="items-grid tools">
                      {items}
                    </ul>
                </div>}
                {this.props.isLoading && <div className="loading">Загрузка</div>}
                {this.props.hasErrored && <div className="error">Ошибка загрузки происшествий</div>}
                </div>
            </div>
        </div>
      );
    }
  }

Pdd.propTypes = {
  fetchData: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  fetchCounters: PropTypes.func.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
      items: state.items,
      counters: state.counters,
      hasErrored: state.itemsHasErrored,
      isLoading: state.itemsIsLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchData: (url) => {dispatch(itemsFetchData(url))},
      fetchCounters: (url) => {dispatch(countersFetchData(url))},
      updateData: (url) => {dispatch(itemsUpdateData(url))},
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Pdd);
