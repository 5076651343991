import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'

import InputRange from 'react-input-range'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import VideoPlayer from '../../components/VideoPlayer'
import AttentionPopUpWrapper from '../Header/PopUps/AttentionPopUpWrapper'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {CITYLINK} from '../../helpers/constants/HeaderConstants'
import Promo from '../Promo';
import * as PropTypes from "prop-types";
import {CAMERA_TYPE_NEARBY_CAMERAS, CAMERA_TYPE_PUBLIC, CAMERA_TYPE_USER_CAMERAS} from "../UserCameras/UserCamera";
import {NEARBY_CAMERAS_PATH, USER_CAMERAS_PATH} from "../../routes";
import InstallCamerasAd from "../Promo/Citylink/ads/InstallCamerasAd";

/**
 * Контейнер браузера Архива
 */
export default class BrowserContainer extends Component {
    render() {
        let camera = this.props.camera
        const playlistUrl = this.props.playlistUrl

        let cameraType = this.props.cameraType
        let basePath;

        switch (cameraType) {
            case CAMERA_TYPE_PUBLIC:
                basePath = '/pub';
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                basePath = USER_CAMERAS_PATH
                break;
            case CAMERA_TYPE_NEARBY_CAMERAS:
                basePath = NEARBY_CAMERAS_PATH
                break
        }

        let isPublic = cameraType === CAMERA_TYPE_PUBLIC

        //2023 Январь 31 - временно убираем блок "Создать обращение"
        let showWitnessBlock = false

        return (
            <div className="content-wrapper wide">
                <div className="content-archive_tool">
                    <div className="input-form__wrapper">
                        <div className="calendar">
                            <label>Выберите дату:</label>
                            {this.props.calendar}
                        </div>
                        <div className="timeline">
                            <label>Выберите диапазон времени:</label>
                            <div className="input-range__wrapper">
                                <InputRange
                                    draggableTrack
                                    allowSameValues={true}
                                    formatLabel={value => this.props.getTimeFromVal(value)}
                                    maxValue={1439}
                                    minValue={0}
                                    onChange={this.props.changed}
                                    value={this.props.values}/>
                            </div>
                        </div>
                        <div className="user-buttons">
                            <label>Выбранный диапазон просмотра:</label>
                            <div className="begin-wrapper">
                                <input
                                    type="text"
                                    className="pseudo-input"
                                    key={this.props.generateKey(this.props.display)}
                                    disabled
                                    defaultValue={this.props.display}
                                />
                                <input
                                    type="text"
                                    className="begin"
                                    defaultValue={this.props.getTimeFromVal(this.props.minValue)}
                                    onKeyUp={this.props.changedMin}
                                />
                            </div>
                            <div className="end-wrapper">
                                <input
                                    type="text"
                                    className="pseudo-input"
                                    key={this.props.generateKey(this.props.display)}
                                    disabled
                                    defaultValue={this.props.display}
                                />
                                <input
                                    type="text"
                                    className="end"
                                    defaultValue={this.props.getTimeFromVal(this.props.maxValue)}
                                    onKeyUp={this.props.changedMax}
                                />
                            </div>

                            <button className="search" onClick={() => this.props.handleSearch(camera)}>Искать запись
                            </button>
                        </div>
                    </div>
                </div>

                {/*<div className="video-player archive" key={this.props.generateKey(this.props._search)}>*/}
                <div className="video-player archive">
                    <AttentionPopUpWrapper
                        textFirst={this.props.attentionTextFirst}
                        textSecond={this.props.attentionTextSecond}
                        showPopUp={this.props.showAttentionPopUp}
                        handleClosePopUp={this.props.handleClosePopUp}
                    />
                    <div className="video_and_promo">
                        <VideoPlayer
                            key={playlistUrl}
                            width={'770'}
                            height={'433'}
                            source={playlistUrl}
                            audio={camera.audio}
                            poster={camera.img}
                            type={'application/x-mpegURL'}
                            isArchive={true}
                            cameraId={camera.id}
                            cameraType={this.props.cameraType.substring(0, this.props.cameraType.length - 8)} //отрезаем "_cameras"
                        />
                        {
                            CITYLINK &&
                            <div style={{marginLeft: 25}}>
                                <Promo/>
                            </div>
                        }

                        {/*Блок "Заявить о проишествии", находится справа от видео блока*/}
                        {
                            showWitnessBlock && !this.props.isPublic && this.props._download && this.props.paramsSearch !== undefined &&
                            <div className="sosed-archive__wrapper">
                                <div className="sosed-back">
                                    <div className="sosed-description">
                                        <p>Вы выбрали запись, на которой заснято происшествие (ДТП, драка, вандализм и
                                            т.д.)?</p>
                                        <p>У Вас есть возможность найти свидетелей среди жильцов Вашего и соседних домов
                                            (клиентов
                                            «Ситилинка»): просто отправьте им обращение вместе с этой записью!</p>
                                        <p>Для этого выполните несколько простых шагов:</p>
                                    </div>
                                    <button className="sosed" onClick={() => this.props.handleSosed()}>Создать обращение
                                    </button>
                                </div>
                                <div className="sosed-question" onClick={() => this.props.handleQuestion()}>Что это?
                                </div>
                                {
                                    this.props.isQuestion &&
                                    <div className="sosed-answer">
                                        <div className="sosed-answer_close"
                                             onClick={() => this.props.handleQuestion()}></div>
                                        <p>У любого абонента «Ситилинка» есть возможность создавать обращения к жильцам
                                            своего и соседних домов,
                                            чтобы быстро найти свидетелей происшествий, которые зафиксировали камеры
                                            видеонаблюдения. </p>
                                        <p>Все обращения хранятся в <Link to='/sosedi'>специальном разделе</Link>.</p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        camera !== undefined && (
                            <>
                                <div className="video-player-info">
                                    <span className="video-player_name">{camera.name}</span>
                                    <Link className="video-player_link"
                                          to={`${basePath}/${camera.id}`}>Онлайн</Link>
                                    <Link className="video-player_link"
                                          to={`${basePath}/${camera.id}/screen`}>Снимки</Link>
                                    {
                                        isPublic && camera.incident !== 0 &&
                                        <Link className="items-grid__item_arcLink screen"
                                              to={`/public/videos/${camera.id}`}>
                                            Происшествия
                                        </Link>
                                    }
                                    <span>
                                    {
                                        this.props._download && this.props._search &&
                                        <a className="video-player_link" href='# '
                                           onClick={() => this.props.handleShare()}>
                                            Поделиться
                                        </a>
                                    }
                                        <a className="video-player_link" href='# '
                                           onClick={() => this.props.handleGetArchiveVideo(camera)}>
                                        Скачать
                                    </a>
                                </span>
                                </div>
                                <InstallCamerasAd/>
                            </>
                        )
                    }
                    {
                        this.props.isSosed &&
                        <div>
                            <div className="alpha-block"/>
                            <div className="share-wrapper sosed-archive">
                                {
                                    this.props.sosedStep < 6 &&
                                    <div className="share-wrapper_close" onClick={() => this.props.handleSosed()}/>
                                }

                                {this.props.sosedStep < 6 &&
                                    <div className="sosed-wizzard">
                                        <div className="sosed-wizzard_bar">
                                            <div
                                                className={(this.props.filledStep1 ? ' filled' : '') + (this.props.sosedStep === 1 ? ' active' : '') + ' sosed-wizzard_step step-1'}>
                                                <label onClick={() => this.props.handleStep(1)}>Видео</label>
                                                <span onClick={() => this.props.handleStep(1)}/>
                                            </div>
                                            <div
                                                className={(this.props.filledStep2 ? ' filled' : '') + (this.props.sosedStep === 2 ? ' active' : '') + ' sosed-wizzard_step step-2'}>
                                                <label onClick={() => this.props.handleStep(2)}>Имя</label>
                                                <span onClick={() => this.props.handleStep(2)}/>
                                            </div>
                                            <div
                                                className={(this.props.filledStep3 ? ' filled' : '') + (this.props.sosedStep === 3 ? ' active' : '') + ' sosed-wizzard_step step-3'}>
                                                <label onClick={() => this.props.handleStep(3)}>Текст</label>
                                                <span onClick={() => this.props.handleStep(3)}/>
                                            </div>
                                            <div
                                                className={(this.props.filledStep4 ? ' filled' : '') + (this.props.sosedStep === 4 ? ' active' : '') + ' sosed-wizzard_step step-4'}>
                                                <label onClick={() => this.props.handleStep(4)}>Соседи</label>
                                                <span onClick={() => this.props.handleStep(4)}/>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.props.sosedStep === 1 &&
                                    <div>
                                        <div className="sosed-step step-1">
                                            <h3>1. Видео</h3>
                                            {!this.props.CalculateDownload(true) &&
                                                <div className="step-text">
                                                    <p><span className="red-text">Вы выбрали длинный видеоролик.</span>
                                                    </p>
                                                    <p>Лучше отправлять ролик, где сразу видно происшествие (не более 20
                                                        минут), чтобы соседям не
                                                        пришлось его искать. Будете менять длительность видеоролика?</p>
                                                    <p>Если да, то закройте это окно и выберите необходимый диапазон
                                                        времени, нажмите «Искать запись».
                                                        После этого вернитесь сюда, нажав «Создать обращение».</p>
                                                    <p>Если нет, то нажмите «Далее».</p>
                                                </div>}

                                            {this.props.CalculateDownload(true) &&
                                                <div className="step-text">
                                                    <p>Вы выбрали видео, которое хотите отправить соседям? Проверьте его
                                                        в
                                                        окне предпросмотра
                                                        справа.</p>
                                                    <p>Перейти на следующий шаг с этим видео?</p>
                                                    <p>Если да, нажмите «Далее».</p>
                                                    <p>Если нет, то закройте это окно и выберите необходимый диапазон
                                                        времени, нажмите «Искать
                                                        запись». После этого вернитесь сюда, нажав «Создать
                                                        обращение».</p>
                                                </div>
                                            }
                                            <div className="step-buttons">
                                                <button className="prev disabled">Назад</button>
                                                <button className="next" onClick={() => this.props.handleStep(2)}>Далее
                                                </button>
                                            </div>
                                        </div>

                                        <div className="share-wrapper_preview">
                                            <VideoPlayer
                                                customId={'camera_preview'}
                                                width={'430'}
                                                height={'242'}
                                                source={playlistUrl}
                                                audio={camera.audio}
                                                poster={camera.img}
                                                type={'application/x-mpegURL'}
                                                isArchive={true}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    this.props.sosedStep === 2 &&
                                    <div className="sosed-step step-2">
                                        <h3>2. Представьтесь, пожалуйста</h3>
                                        <div className="step-text name">
                                            <p><label>Имя: </label> <input
                                                id="sosedName"
                                                type="text"
                                                onChange={({target: {value}}) => this.props.handleSosedName(value)}
                                                defaultValue={this.props.sosedName}
                                            />
                                            </p>
                                            <p>
                                                <label>Телефон: </label> <input id="sosedPhone"
                                                                                onChange={({target: {value}}) => this.props.handleSosedPhone(value)}
                                                                                type="text"
                                                                                defaultValue={this.props.sosedPhone}
                                            />
                                            </p>
                                            <div>
                                                <label/>
                                                <span className="info-text">Ваш номер необходим для обратной связи с администрацией сайта. <br/>
                                      В обращении соседи увидят только Ваше имя
                      </span>
                                            </div>
                                        </div>
                                        <div className="step-buttons">
                                            <button className="prev" onClick={() => this.props.handleStep(1)}>Назад
                                            </button>
                                            <button className="next" onClick={() => this.props.handleStep(3)}>Далее
                                            </button>
                                        </div>
                                    </div>
                                }

                                {
                                    this.props.sosedStep === 3 &&
                                    <div className="sosed-step step-3">
                                        <h3>3. Опишите происшествие</h3>
                                        <div className="step-text name">
                                            <p><label>Название: </label> <input
                                                id="sosedTitle"
                                                onChange={({target: {value}}) => this.props.handleSosedTitle(value)}
                                                type="text"
                                                defaultValue={this.props.sosedTitle}/>
                                            </p>
                                            <div><label></label><span className="info-text">
                                                        Пример: "Поцарапали машину", "Драка", "Сломали скамейку" и т.д.
                                </span></div>
                                            <p><label>Описание: </label> <textarea
                                                id="sosedDescr"
                                                type="text"
                                                onChange={({target: {value}}) => this.props.handleSosedDescr(value)}
                                                defaultValue={this.props.sosedDescr}
                                            />
                                            </p>
                                            <div>
                                                <label></label>
                                                <span className="info-text">
                        Подробно опишите, что произошло, укажите точное время происшествия
                      </span>
                                            </div>
                                        </div>
                                        <div className="step-buttons">
                                            <button className="prev" onClick={() => this.props.handleStep(2)}>Назад
                                            </button>
                                            <button className="next" onClick={() => this.props.handleStep(4)}>Далее
                                            </button>
                                        </div>
                                    </div>}

                                {
                                    this.props.sosedStep === 4 &&
                                    <div className="sosed-step step-4">
                                        <h3>4. Выберите дома соседей</h3>
                                        <div className="step-text streets">
                                            <p>Жителям этих домов, абонентам «Ситилинка», будет отправлено Ваше
                                                обращение.</p>
                                            <p>Рядом представлен список рекомендуемых домов. При необходимости удалите
                                                дома из списка и/или
                                                добавьте новые дома</p>
                                        </div>
                                        <div className="step-scroll">
                                            <input
                                                id="sosedStreet"
                                                onKeyUp={({target: {value}}) => this.props.handleSearchStreet(value)}
                                                placeholder="Улица"
                                                type="text"
                                            />
                                            {
                                                this.props.isSuccessSearch &&
                                                <div className="search-result">
                                                    <ul>
                                                        {
                                                            this.props.findedStreets.map(
                                                                (street, i) =>
                                                                    <li className="items-search__item"
                                                                        onClick={() => this.props.handleSelectStreet(street)}
                                                                        key={i}>{street}
                                                                    </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            }

                                            <input id="sosedHome" placeholder="Дом" type="text"/>
                                            <button className="add" onClick={() => this.props.handleSosedStreet()}>
                                                Добавить
                                            </button>
                                            <div className="scroll-list">
                                                <PerfectScrollbar>
                                                    <ul className="streetAndHome"
                                                        key={this.props.generateKey(this.props.manualStreets.length)}>
                                                        {this.props.manualStreets}
                                                        {this.props.sosedStreets}
                                                    </ul>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>

                                        <div className="step-buttons">
                                            <button className="prev" onClick={() => this.props.handleStep(3)}>Назад
                                            </button>
                                            <button className="next" onClick={() => this.props.handleStep(5)}>Далее
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.props.sosedStep === 5 &&
                                    <div className="sosed-step step-5">
                                        <h3>Ваше обращение готово</h3>
                                        <div className="step-text streets">
                                            <p>При необходимости отредактируйте информацию на предыдущих шагах («Видео»,
                                                «Имя», «Текст»,
                                                «Соседи»).</p>
                                            <p>Если все в порядке, то ознакомьтесь и согласитесь с правилами и нажмите
                                                кнопку «Отправить»</p>
                                        </div>
                                        <div className="step-scroll">
                                            <div className="scroll-list rules">
                                                <PerfectScrollbar>
                                                    <p>1. Размещая обращение на сайте, Вы гарантируете достоверность
                                                        изложенной информации. В случае
                                                        выявления ложной информации или серьезных искажений - обращение
                                                        может быть заблокировано или
                                                        удалено администрацией сайта без возможности восстановления.</p>
                                                    <p>2. Вы подтверждаете, что соглашаетесь отправить запись с камеры и
                                                        информацию о происшествии
                                                        жителям домов, указанных Вами на шаге «Соседи». Компания
                                                        «Ситилинк» в целях повышения
                                                        эффективности поиска, оставляет за собой право использовать все
                                                        доступные для этих целей
                                                        механизмы: размещение видеозаписи происшествия в Интернете,
                                                        информирование абонентов и
                                                        т.д.</p>
                                                    <p>3. Относитесь с уважением к другим пользователям, не используйте
                                                        грубые выражения и
                                                        ненормативную лексику при общении с ними. Все тексты и
                                                        комментарии с неприличной формой
                                                        высказывания могут быть удалены администрацией сайта без
                                                        возможности восстановления.</p>
                                                </PerfectScrollbar>
                                            </div>
                                            <label id="rules">
                                                <input
                                                    id="rule"
                                                    name="rules"
                                                    type="checkbox"
                                                    onChange={() => this.props.handleRules()}
                                                />
                                                <span>Я согласен с правилами</span>
                                            </label>
                                        </div>
                                        {
                                            this.props.sosedError &&
                                            <span className="error-sosed">{this.props.sosedError}</span>
                                        }
                                        <div className="step-buttons">
                                            <button className="prev" onClick={() => this.props.handleStep(4)}>Назад
                                            </button>
                                            <button
                                                id="finish"
                                                className="next disabled"
                                                onClick={() => this.props.handleStep(6)}
                                            >
                                                Далее
                                            </button>
                                        </div>
                                    </div>}
                                {
                                    this.props.sosedStep === 6 &&
                                    <div className="sosed-step step-6 sended">
                                        <h3>Отправлено!</h3>
                                        <div className="step-text name">
                                            <p>Обращение опубликовано в разделе «Ищу свидетелей!»</p>
                                            <p><Link to={`/my/${this.props.sosedResponse}`}>Посмотреть</Link></p>
                                            <p>После прохождения модерации оно станет доступно Вашим соседям в течение
                                                нескольких часов.</p>
                                            <p>Следите за комментариями на странице обращения и оставляйте свои. Эти
                                                действия помогут получить
                                                необходимую информацию о происшествии.</p>
                                        </div>
                                        <div className="step-buttons">
                                            <button className="next"
                                                    onClick={() => this.props.handleSosed(true)}>Закончить
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.props.sosedStep === 7 &&
                                    <div className="sosed-step step-7">
                                        <div className="step-text unfinish">
                                            <p>В прошлый раз Вы заполнили не все поля обращения.</p>

                                            <p>Продолжить работу c тем же обращением, или очистить все поля, чтобы
                                                создать новое обращение?</p>
                                        </div>
                                        <div className="step-buttons">
                                            <button className="prev"
                                                    onClick={() => this.props.handleContinue()}>Продолжить
                                            </button>
                                            <button className="next" onClick={() => this.props.handlePurge()}>Заново
                                            </button>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    }

                    {
                        this.props.isShare &&
                        <div className="share-wrapper">
                            <div className="share-wrapper_close" onClick={() => this.props.handleShare()}/>
                            {
                                !this.props.share &&
                                <div>
                                    <div className="share-wrapper_header">
                                        Поделиться видео
                                    </div>
                                    <div className="share-wrapper_text">
                                        <p>Вы выбрали видео, которым хотите поделиться?</p>

                                        <p>Если да, то введите название видеоролика и нажмите «Получить ссылку».</p>

                                        <p>Если нет, то закройте это окно и выберите необходимый диапазон времени,
                                            нажмите «Искать запись».
                                            После этого вернитесь сюда, нажав кнопку «Поделиться видео».</p>
                                    </div>
                                    <div className="share-wrapper_buttons">
                                        <input
                                            type="text"
                                            id="share_video_name"
                                            defaultValue=""
                                            placeholder="Название видео"
                                        />
                                        <button className="get-link" onClick={() => this.props.handleShareGenerate()}>
                                            Получить ссылку
                                        </button>
                                    </div>
                                    <div className="share-wrapper_preview">
                                        <VideoPlayer
                                            customId={'share_preview'} width={'430'}
                                            height={'242'}
                                            source={playlistUrl}
                                            audio={camera.audio}
                                            poster={camera.img}
                                            type={'application/x-mpegURL'}
                                            isArchive={true}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.props.share &&
                                <div>
                                    <div className="share-wrapper_header">
                                        Ссылка на видео
                                    </div>
                                    <div className="share-wrapper_text">
                                        <p>Ваше видео загружено, теперь Вы можете поделиться ссылкой.</p>
                                    </div>
                                    <div className="share-wrapper_buttons">
                                        <input
                                            type="text"
                                            value={this.props.share}
                                            onChange={({target: {value}}) => this.props.setShareValue(value)}
                                        />

                                        <CopyToClipboard
                                            text={this.props.share}
                                            onCopy={() => this.props.setValueWasCopied()}
                                        >
                                            <button className="copy-to-clp">Копировать ссылку</button>
                                        </CopyToClipboard>
                                        {
                                            this.props.copied &&
                                            <span className="copy-success">Ссылка скопирована в буфер обмена</span>
                                        }
                                    </div>
                                    <div className="share-wrapper_preview">
                                        <VideoPlayer
                                            id="preview" width={'430'}
                                            height={'242'}
                                            source={playlistUrl}
                                            audio={camera.audio}
                                            poster={camera.img}
                                            type={'application/x-mpegURL'}
                                            isArchive={true}/>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    this.props.paramsSearch !== undefined && camera === undefined && !this.props.isPubPath &&
                    <Redirect push to='/'/>
                }
            </div>
        )
    }
}

BrowserContainer.propTypes = {
    cameraType: PropTypes.string.isRequired,
    playlistUrl: PropTypes.string.isRequired
}
