import {Component} from "react";
import '../sass/BannerNotification.scss'


export default class BannerNotification extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showBanner: true
        }
    }

    closeBanner = () => {
        this.setState({
            showBanner: !this.state.showBanner
        })
    }

    render() {
        if (!this.state.showBanner) {
            return null;
        }
        return (
            <div className="wbbanner__layout">
                <div className="wbbanner">
                    <div className="wbbanner__logo">

                    </div>

                    <div className="wbbanner__close"
                    onClick={this.closeBanner}
                    >
                    </div>

                    <div className="wbbanner__content">
                        <p className="wbbanner__title">
                            Уважаемые Клиенты!
                        </p>
                        <p className="wbbanner__p">
                            <b>20 ноября с 1:00 до 7:00</b> на сайте <br/> будут проводиться технические работы.
                        </p>

                        <p className="wbbanner__p">
                            В это время возможны трудности с просмотром камер. <br/> Часть архива за указанный период будет недоступна.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}