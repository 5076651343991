import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {BROWSER, MOBILE} from '../../helpers/constants/HeaderConstants';
import {mdApi} from "../../index";
import VideoPlayer from '../../components/VideoPlayer';
import * as PropTypes from "prop-types";
import {NEARBY_CAMERAS_PATH, USER_CAMERAS_PATH} from "../../routes";
import cameraMockPreview from "../../images/play.png"

export const CAMERA_TYPE_NEARBY_CAMERAS = "nearby_cameras";
export const CAMERA_TYPE_USER_CAMERAS = "user_cameras";
export const CAMERA_TYPE_PUBLIC = "public_cameras";

export default class UserCamera extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            camera: undefined,
            cameras: [],
            cameraRandomKey:Math.floor(Math.random()*Number.MAX_SAFE_INTEGER)
        };
    }

    fetchCamera = (cameraId, cameraType) => {
        if (cameraType === CAMERA_TYPE_USER_CAMERAS) {
            return this.fetchUserCameraById(cameraId)
        } else if (cameraType === CAMERA_TYPE_NEARBY_CAMERAS) {
            return this.fetchNearbyCameraById(cameraId)
        }
    }

    fetchCameras = (cameraType) => {
        if (cameraType === CAMERA_TYPE_USER_CAMERAS) {
            return this.fetchUserCameras()
        } else if (cameraType === CAMERA_TYPE_NEARBY_CAMERAS) {
            return this.fetchNearbyCameras()
        }
    }

    updateCamera = () => {
        let cameraId = this.props.match.params.cameraId;
        let cameraType = this.props.camerasType

        return this.fetchCamera(cameraId, cameraType)
            .then(camera => {
                this.setState({camera,cameraRandomKey:Math.floor(Math.random()*Number.MAX_SAFE_INTEGER)})
            })
    }

    updateCameras = () => {
        let cameraType = this.props.camerasType

        return this.fetchCameras(cameraType)
            .then(cameras => {
                this.setState({cameras})
            })
    }

    fetchUserCameraById = (cameraId) => {
        return mdApi.userCamerasById(cameraId)
    }

    fetchUserCameras = () => {
        return mdApi.userCamerasAll()
    }

    fetchNearbyCameraById = (cameraId) => {
        return mdApi.nearbyCamerasById(cameraId)
    }

    fetchNearbyCameras = () => {
        return mdApi.nearbyCamerasAll()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevCameraId = prevProps.match.params.cameraId;
        let cameraId = this.props.match.params.cameraId;

        if (prevCameraId !== cameraId) {
            this.updateCamera()
                .then()
                .catch()

            this.updateCameras()
                .then()
                .catch()
        }
    }

    componentDidMount() {
        this.updateCamera()
            .then()
            .catch()

        this.updateCameras()
            .then()
            .catch()
    }

    render() {
        let camera = this.state.camera;

        if (camera === undefined) {
            return null;
        }

        let cameraId = camera.id
        let cameraName = camera.name

        let cameraType = this.props.camerasType

        let basePath;

        if (cameraType === CAMERA_TYPE_USER_CAMERAS) {
            basePath = USER_CAMERAS_PATH
        } else if (cameraType === CAMERA_TYPE_NEARBY_CAMERAS) {
            basePath = NEARBY_CAMERAS_PATH
        }

        let otherCameras = this.state.cameras.map(otherCamera => {
            let cameraId = otherCamera.id
            let cameraName = otherCamera.name
            let img = process.env.NODE_ENV === 'development' ? cameraMockPreview : otherCamera.img

            return <li className="items-grid__item" key={cameraId}>
                <Link className="items-grid__item_link" to={`${basePath}/${cameraId}`}>
                    <img src={img} alt={cameraName}/>
                    <span
                        className="items-name truba-mode">{cameraName}</span>
                </Link>
                <Link className="items-grid__item_arcLink" to={`${basePath}/${cameraId}/archive`}>
                    Архив
                </Link>
                <Link className="items-grid__item_arcLink screen" to={`${basePath}/${cameraId}/screen`}>
                    Снимки
                </Link>
            </li>
        });

        let randomKey = this.state.cameraRandomKey;

        return (
            <div>
                {
                    BROWSER &&
                    <div className="content-wrapper">
                        <div className={otherCameras.length === 0 ? "video-player single" : "video-player"}>
                            <VideoPlayer width={'770'}
                                         key={randomKey}
                                         height={'433'}
                                         source={camera.src}
                                         audio={camera.audio}
                                         poster={camera.img}
                                         type={'application/x-mpegURL'}
                                         isArchive={this.state.isArchive}
                                         cameraId={camera.id}
                                         cameraType={this.props.camerasType.substring(0, this.props.camerasType.length - 8)} //отрезаем "_cameras"
                            />
                            <div className="video-player-info">
                                <span className="video-player_name">{cameraName}</span>
                                <Link className="video-player_link"
                                      to={`${basePath}/${cameraId}/archive`}>Архив</Link>
                                <Link className="video-player_link"
                                      to={`${basePath}/${cameraId}/screen`}>Снимки</Link>
                            </div>
                        </div>
                        {
                            otherCameras.length !== 0 &&
                            <ul className="items-grid right-column">
                                {otherCameras}
                            </ul>
                        }
                    </div>
                }
                {
                    MOBILE &&
                    <div className="mobile-wrapper">
                        <div className="mobile-video-player">
                            <VideoPlayer width={'350'}
                                         key={randomKey}
                                         height={'197'}
                                         source={camera.src}
                                         audio={camera.audio}
                                         poster={camera.img}
                                         type={'application/x-mpegURL'}
                                         isArchive={this.state.isArchive}
                                         cameraId={camera.id}
                                         cameraType={this.props.camerasType.substring(0, this.props.camerasType.length - 8)} //отрезаем "_cameras"
                            />
                            <div className="video-player-info">
                                <span className="video-player_name">{cameraName}</span>
                                <Link className="video-player_link"
                                      to={`${basePath}/${cameraId}/archive`}>Архив</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

UserCamera.propTypes = {
    camerasType: PropTypes.string.isRequired
}
