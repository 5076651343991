import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import VideoPlayer from '../components/VideoPlayer'
import {
    BROWSER,
    MOBILE,
    cityNameFormatted
} from '../helpers/constants/HeaderConstants'

import '../sass/PublicCameraWithPromo.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CameraPublicWithPromoTags from "./Header/Metatags/CameraPublicWithPromoTags";
import Promo from './Promo';
import {mdApi} from "../index";
import {Component} from "react";
import InstallCamerasAd from "./Promo/Citylink/ads/InstallCamerasAd";

class CameraPublicWithPromo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            camera: undefined
        }
    }

    componentDidMount() {
        let cameraId = this.props.match.params.cameraId

        mdApi.publicCamerasById(cameraId)
            .then(camera => this.setState({camera}))
            .catch(() => {
                this.props.history.push("/404")
            })
    }

    generateKey = (pre) => {
        return `camera_${pre}`
    }

    render() {
        // const id = this.props.match.params.cameraId
        // const _items = this.props.publicCamsList

        // let randomProperty = (obj) => {
        //     let keys = Object.keys(obj)
        //     return keys[keys.length * Math.random() << 0]
        // }

        // let randomItems = []
        // let length = Object.keys(_items).length
        //
        // if (length) {
        //     let i = 0
        //     while (i < length - 1) {
        //         let _randomItem = randomProperty(_items)
        //
        //         if (_randomItem !== id && randomItems.indexOf(_randomItem) < 0) {
        //             randomItems.push(_randomItem)
        //             i++
        //         }
        //     }
        // }

        // let _randomItems = []
        //
        // randomItems.forEach((item) => {
        //     const random = _items[item]
        //
        //     _randomItems.push(<li className="items-grid__item" key={item}>
        //         <Link className="items-grid__item_link" to={`/pub/${item}`}>
        //             <img src={random.img} alt={random.name}/>
        //             <span className="items-name truba-mode">{random.name}</span>
        //         </Link>
        //         <Link className="items-grid__item_arcLink" to={`/pub/${item}/archive`}>
        //             Архив
        //         </Link>
        //         <Link className="items-grid__item_arcLink screen" to={`/pub/${item}/screen`}>
        //             Снимки
        //         </Link>
        //     </li>)
        // })

        let camera = this.state.camera
        let audio = false

        // #11590 баг с редиректом при переходе по прямой ссылке на публичную камеру https://moidom.citylink.pro/pub/3173
        // компонент рэндерится 3 раза (по-непонятной причине), в первые 2 раза получает пустые данные / данные, в которых нет нужной камеры
        // 131 строка
        // {(((Object.keys(_items).length > 0 && _items[id] === undefined) || _items === -1) && !this.props.match.path.match('noredirect')) && <Redirect push to='/publist'/>}

        return (
            <React.Fragment>
                {
                    camera !== undefined &&
                    <div>
                        <CameraPublicWithPromoTags
                            street={camera.name}
                            city1={cityNameFormatted(camera.city.name)[0]}
                            city2={cityNameFormatted(camera.city.name)[1]}
                            cameraID={camera.id}
                        />
                        {
                            BROWSER &&
                            <div className="content-wrapper" style={{gap: 25}} key={this.generateKey(camera.id)}>
                                <div className="public-camera-left-block">
                                    <div className={'video-player single'}>
                                        <VideoPlayer width={'770'}
                                                     height={'433'}
                                                     source={camera.src}
                                                     audio={audio}
                                                     poster={camera.img}
                                                     type={'application/x-mpegURL'}
                                                     isArchive={false}
                                                     cameraId={camera.id}
                                                     cameraType={"public"}
                                        />
                                        <div className="video-player-info">
                                            <span className="video-player_name"><h1>{camera.name}</h1></span>
                                            <Link className="video-player_link"
                                                  to={`/pub/${camera.id}/archive`}>Архив</Link>
                                            <Link className="video-player_link"
                                                  to={`/pub/${camera.id}/screen`}>Снимки</Link>
                                            {
                                                camera.incident ?
                                                    <Link className="video-player_link"
                                                          to={`/public/videos/${camera.id}`}>
                                                        Происшествия
                                                    </Link> : ''
                                            }
                                        </div>
                                        <InstallCamerasAd />
                                    </div>
                                </div>
                                <Promo/>
                            </div>
                        }
                        {
                            MOBILE &&
                            <div className="mobile-wrapper">
                                <div className="mobile-video-player">
                                    <VideoPlayer width={'350'}
                                                 height={'197'}
                                                 source={camera.src}
                                                 audio={audio}
                                                 poster={camera.img}
                                                 type={'application/x-mpegURL'}
                                                 isArchive={false}
                                                 cameraId={camera.id}
                                                 cameraType={"public"}
                                    />
                                    <div className="video-player-info">
                                        <span className="video-player_name"><h1>{camera.name}</h1></span>
                                        <Link className="video-player_link"
                                              to={`/pub/${camera.id}/archive`}>Архив</Link>
                                        {
                                            camera.incident !== 0 &&
                                            <Link className="video-player_link" to={`/public/videos/${camera.id}`}>
                                                Происшествия
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <Promo type='mobile'/>
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }

}

export default withRouter(CameraPublicWithPromo)
