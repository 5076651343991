import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import {NEARBY_CAMERAS_PATH, PUBLIC_MAP_PATH, USER_CAMERAS_PATH} from "../../../routes";
import {mdApi} from "../../../index";

/**
 * Браузер Ситилинка
 */
export class BrowserCitylink extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasSpecialAccess: false
        }
    }

    fetchHasSpecialAccess() {
        return mdApi.hasSpecialAccess();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.fetchHasSpecialAccess().then((response) => {
                if (response.status === 200) {
                    this.setState({
                        hasSpecialAccess: true
                    })
                }
            })
        }
    }

    handleLinkActiveState = () => {
        return this.props.checkActivePage('pub');
    }

    render() {
        const hasSpecialAccess = this.state.hasSpecialAccess;

        return (
            <ul className="links links-center nearby_cameras">
                <li className="list__item" key='cameras'>
                    <NavLink to={USER_CAMERAS_PATH} isActive={() => this.props.checkActivePage(USER_CAMERAS_PATH)}>
                        Мои камеры
                    </NavLink>
                </li>
                <li className="list__item" key='nearby-cameras'>
                    <NavLink to={NEARBY_CAMERAS_PATH}
                             isActive={() => this.props.checkActivePage(NEARBY_CAMERAS_PATH)}>Дворовые</NavLink>
                </li>
                <li className="list__item" key='public'>
                    <NavLink to={PUBLIC_MAP_PATH}
                             isActive={() => this.handleLinkActiveState(PUBLIC_MAP_PATH)}>Публичные</NavLink>
                </li>
                <li className="list__item" key='videos'>
                    <NavLink
                        to='/videos'
                        isActive={() => (this.props.checkActivePage('/videos') || this.props.checkActivePage('/pdd'))}>
                        Проект в действии
                    </NavLink>
                </li>
                <li className="list__item" key='sosedi'>
                    <NavLink to='/sosedi'
                             isActive={() => this.props.checkActivePage('/sosedi') || this.props.checkActivePage('/my')}>
                        Ищу свидетелей!
                    </NavLink>
                </li>
                <li className="list__item" key='smart sensors'>
                    <NavLink to='/sensors' isActive={() => this.props.checkActivePage('/sensors')}>Умные
                        датчики</NavLink>
                </li>
                {hasSpecialAccess &&
                    <li className="list__item" key='special access'>
                        <NavLink to='/spec' isActive={() => this.props.checkActivePage('/spec')}>Спец доступ</NavLink>
                    </li>
                }
            </ul>
        )
    }
}

export default BrowserCitylink
