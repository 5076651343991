import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {PropTypes} from 'prop-types'
import {
    GET_SEARCH_STREET_URL,
    GET_SOSED_SEND_URL,
    itemsFetchData,
    SHARE_LINK
} from '../actions/items'
import axios from 'axios'

import {BROWSER, cookies, MOBILE} from '../helpers/constants/HeaderConstants'
import {archiveCalendar} from '../helpers/functions/Archive/archiveCalendar'

import 'react-perfect-scrollbar/dist/css/styles.css'
import '../sass/Archive.css'

import MobileContainer from './Archive/MobileContainer'
import BrowserContainer from './Archive/BrowserContainer'
import ArchiveTags from "./Header/Metatags/ArchiveTags";
import {mdApi} from "../index";
import {AppContext, NEARBY_CAMERAS_PATH, USER_CAMERAS_PATH} from "../routes";
import SignInModal from "./Archive/Pay/SignInModal";
import BuyAccessModal from "./Archive/Pay/BuyAccessModal";
import {CAMERA_TYPE_NEARBY_CAMERAS, CAMERA_TYPE_PUBLIC, CAMERA_TYPE_USER_CAMERAS} from "./UserCameras/UserCamera";
import {DateTime} from "luxon";

const attentionTextFirst = 'Вы можете скачать запись с камеры'
const attentionTextSecond = 'в пределах 3 часов.'

export const timeZoneNames = {

}

class Archive extends Component {
    componentDidMount() {
        let url = new URL(location.href)
        let searchParams = url.searchParams

        let previouslyRequestedArchiveDownload = searchParams.has('purchase')

        this.updateCamera()
            .then(() => {
                this.handleSearch(this.state.camera)
                if (previouslyRequestedArchiveDownload) {
                    let camera = this.state.camera

                    let cameraId = camera.id
                    let cameraName = camera.name
                    let cameraType = this.props.cameraType

                    this.requestBuyAccessModal(cameraId, cameraType, cameraName)
                }
            })
    }

    requestBuyAccessModal = (cameraId, cameraType, cameraName) => {
        let menuId;
        let menuProvider;

        let Menu = <BuyAccessModal cameraId={cameraId} cameraType={cameraType} cameraName={cameraName} onClose={() => {
            menuProvider.closeWindow(menuId)
        }}/>

        menuProvider = this.context.getMenuProvider()

        menuId = menuProvider.addWindow(Menu)
    }

    requestSignInModal = () => {
        let menuId;
        let menuProvider;

        let url = new URL(location.href)
        let searchParams = url.searchParams
        searchParams.append('purchase', '')

        let Menu = <SignInModal redirectUrl={url} onClose={() => {
            menuProvider.closeWindow(menuId)
        }}/>

        menuProvider = this.context.getMenuProvider()

        menuId = menuProvider.addWindow(Menu)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.search !== this.props.match.params.search) {
            this.setState({
                _search: this.props.match.params.search,
                display: this.getMaxDefaultDate(),
                _download: this.CalculateDownload(),
                values: {
                    min: this.getMinDefaultTime(),
                    max: this.getMaxDefaultTime(),
                },
            })
            document.getElementsByClassName('begin')[0].value = this.getTimeFromVal(this.getMinDefaultTime())
            document.getElementsByClassName('end')[0].value = this.getTimeFromVal(this.getMaxDefaultTime())
        }
    }

    updatePlaylistUrl = async (cameraId, cameraType, from, to) => {
        let playlistUrl

        switch (cameraType) {
            case CAMERA_TYPE_PUBLIC:
                playlistUrl = await mdApi.publicCamerasRequestPlaylistUrl(cameraId, from, to)
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                playlistUrl = await mdApi.userCamerasRequestPlaylistUrl(cameraId, from, to)
                break;
            case CAMERA_TYPE_NEARBY_CAMERAS:
                playlistUrl = await mdApi.nearbyCamerasRequestPlaylistUrl(cameraId, from, to)
                break;
        }

        this.setState({
            playlistUrl
        })
    }

    fetchPublicCamera = (cameraId) => {
        return mdApi.publicCamerasById(cameraId)
    }

    fetchUserCamera = (cameraId) => {
        return mdApi.userCamerasById(cameraId)
    }

    fetchNearByCamera = (cameraId) => {
        return mdApi.nearbyCamerasById(cameraId)
    }

    updateCamera = () => {
        let cameraId = this.props.match.params.cameraId
        let cameraType = this.props.cameraType;

        if (cameraType === CAMERA_TYPE_PUBLIC) {
            return this.fetchPublicCamera(cameraId)
                .then(camera => this.setState({camera}))
        } else if (cameraType === CAMERA_TYPE_NEARBY_CAMERAS) {
            return this.fetchNearByCamera(cameraId)
                .then(camera => this.setState({camera}))
        } else if (cameraType === CAMERA_TYPE_USER_CAMERAS) {
            return this.fetchUserCamera(cameraId)
                .then(camera => this.setState({camera}))
        }
    }

    constructor(props) {
        super(props)

        const route = this.props.match

        this.state = {
            camera: undefined,
            playlistUrl: undefined,
            _search: route.params.search,
            _download: this.CalculateDownload(),
            display: this.getMaxDefaultDate(),
            values: {
                min: this.getMinDefaultTime(),
                max: this.getMaxDefaultTime(),
            },
            isShare: false,
            isQuestion: false,
            isSosed: false,
            share: '',
            copied: false,
            isUnfinish: false,
            filledStep1: false,
            filledStep2: false,
            filledStep3: false,
            filledStep4: false,
            sosedStep: 1,
            sosedName: '',
            sosedPhone: '',
            sosedTitle: '',
            sosedDescr: '',
            sosedStreets: [],
            findedStreets: [],
            isSuccessSearch: false,
            manualStreets: [],
            sosedResponse: 0,
            sosedError: null,
            showAttentionPopUp: false,
            range: {
                from: undefined,
                to: undefined
            }
        }

        Archive.contextType = AppContext

        this.changed = value => {
            if (!value) {
                return
            }

            if (
                (value.min < 0) ||
                (value.max > 1439)
            ) {
                return
            }

            document.getElementsByClassName('begin')[0].value = this.getTimeFromVal(value.min)
            document.getElementsByClassName('end')[0].value = this.getTimeFromVal(value.max)

            this.setState({
                values: value
            })
        }

        this.changedMin = e => {
            if (!e) {
                return
            }

            const time = e.target.value
            const re = /[0-2]?\d[:][0-5]\d/g

            if (re.test(time) === true) {
                this.setState({values: {...this.state.values, min: this.recoverValFromTime(time)}})
            }
        }

        this.changedMax = e => {
            if (!e) {
                return
            }

            const time = e.target.value
            const re = /[0-2]?\d[:][0-5]\d/g

            if (re.test(time) === true) {
                this.setState({values: {...this.state.values, max: this.recoverValFromTime(time)}})
            }
        }

        this.setShareValue = this.setShareValue.bind(this)
    }

    getMaxDefaultTime = () => {
        if (this.props.match.params.search !== undefined) {
            const _search = this.props.match.params.search
            const re = /[\d]{12}-[\d]{12}/g

            if (re.test(_search) === true) {
                const hours = _search.substr(21, 2)
                const minutes = _search.substr(23, 2)

                return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
            }
        }

        const time = new Date()
        return time.getHours() * 60 + time.getMinutes()
    }

    getMinDefaultTime = () => {
        if (this.props.match.params.search !== undefined) {
            const _search = this.props.match.params.search
            const re = /[\d]{12}-[\d]{12}/g

            if (re.test(_search) === true) {
                const hours = _search.substr(8, 2)
                const minutes = _search.substr(10, 2)

                return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
            }
        }

        const time = new Date()
        const _return = time.getHours() * 60 + time.getMinutes() - 180

        return _return < 0 ? 0 : _return
    }

    getMaxDefaultDate = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search

            const re = /[\d]{12}-[\d]{12}/g

            if (re.test(_search) === true) {
                const year = _search.substr(0, 4)
                const month = _search.substr(4, 2)
                const day = _search.substr(6, 2)

                return day + '.' + month + '.' + year
            }
        }

        const date = new Date()

        let day = date.getDate()

        if (day < 10) {
            day = '0' + day
        }

        let month = date.getMonth() + 1

        if (month < 10) {
            month = '0' + month
        }

        const year = date.getFullYear()

        return day + '.' + month + '.' + year
    }

    /**
     * Дата по-умолчанию
     *
     * @returns {string|number}
     */
    getCurrentDefaultDate = () => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search

            const re = /[\d]{12}-[\d]{12}/g

            if (re.test(_search) === true) {
                const year = _search.substr(0, 4)
                const month = _search.substr(4, 2)
                const day = _search.substr(6, 2)

                return year + month + day
            }
        }

        return 0
    }

    CalculateDownload = (sosed) => {
        if (this.props.match.params.search !== undefined) {
            let _search = this.props.match.params.search

            const re = /[\d]{12}-[\d]{12}/g

            if (re.test(_search) === true) {
                const hoursBegin = parseInt(_search.substr(8, 2), 10)
                const minutesBegin = parseInt(_search.substr(10, 2), 10)
                const hoursEnd = parseInt(_search.substr(21, 2), 10)
                const minutesEnd = parseInt(_search.substr(23, 2), 10)

                return Math.abs((hoursEnd * 60 + minutesEnd) - (hoursBegin * 60 + minutesBegin)) <= (sosed ? 20 : 180)
            }
        }

        return true
    }

    getTimeFromVal = value => {
        let hour = Math.floor(value / 60)

        if (hour < 10) {
            hour = '0' + hour
        }

        let minutes = Math.abs(value - (hour * 60))

        if (minutes < 10) {
            minutes = '0' + minutes
        }

        return hour + ':' + minutes
    }

    recoverValFromTime = time => {
        const _time = time.split(':')
        return parseInt(_time[0] > 23 ? 23 : _time[0], 10) * 60 + parseInt(_time[1].substr(0, 2), 10)
    }

    generateKey = (pre) => {
        return `${pre}`
    }

    /**
     * Creates UTC+3 Date Object
     *
     * @param {String} dateString
     * @return {Date}
     */
    formatDate = (dateString) => {
        const requiredTimeZone = '+3'

        dateString = `${dateString} ${requiredTimeZone}`

        // const year = Number(dateString.substring(0, 4));
        // const month = Number(dateString.substring(4, 6)) - 1;
        // const day = Number(dateString.substring(6, 8));
        // const hour = Number(dateString.substring(8, 10));
        // const minute = Number(dateString.substring(10, 12));

        const date = DateTime.fromFormat(dateString,'yyyyMMddHHmm Z',)

        return date.toJSDate()
    }

    handleSearch = (camera, mobile) => {
        let getSearchDay = document.getElementsByClassName('calendar-item current')[0]

        if (getSearchDay !== undefined) {
            getSearchDay = getSearchDay.dataset.search
        } else {
            let date = new Date()
            let day = date.getDate()

            if (day < 10) {
                day = '0' + day
            }

            let month = date.getMonth() + 1

            if (month < 10) {
                month = '0' + month
            }

            const year = date.getFullYear()

            getSearchDay = '' + year + month + day + ''
        }

        const getSearchTimeBegin = this.getTimeFromVal(this.state.values.min).replace(':', '')
        const getSearchTimeEnd = this.getTimeFromVal(this.state.values.max).replace(':', '')
        const fromString = getSearchDay + getSearchTimeBegin
        const toString = getSearchDay + getSearchTimeEnd
        const _search = fromString + '-' + toString

        const from = this.formatDate(fromString)
        const to = this.formatDate(toString)

        this.setState({
            _search: _search,
            isShare: false,
            share: '',
            copied: false,
            range: {
                from,
                to
            }
        })

        const cameraId = camera.id
        let cameraType = this.props.cameraType
        let basePath;

        this.updatePlaylistUrl(cameraId, cameraType, from, to)

        switch (cameraType) {
            case CAMERA_TYPE_PUBLIC:
                basePath = '/pub';
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                basePath = USER_CAMERAS_PATH
                break;
            case CAMERA_TYPE_NEARBY_CAMERAS:
                basePath = NEARBY_CAMERAS_PATH
                break
        }

        let path = `${basePath}/${camera.id}/archive/${_search}`;
        if(this.props.location) {
            path += this.props.location.search;
        }
        if (this.props.match.params.search === undefined) {
            this.props.history.replace(path)
        } else {
            this.props.history.push(path)
        }

        if (mobile) {
            window.location.reload()
        }
    }

    handleCalendar = (event) => {
        let _display
        let calendar = document.getElementsByClassName('calendar-item current')
        if (calendar && calendar[0] !== undefined) {
            calendar[0].classList.remove('current')
        }

        const c = event.target.dataset.id

        if (c === undefined) {
            _display = event.target.parentNode.dataset.display
            event.target.parentNode.classList.add('current')
        } else {
            _display = event.target.dataset.display
            event.target.classList.add('current')
        }

        this.setState({display: _display})
    }

    handleShare = () => {
        this.setState(prevState => ({
            isShare: !prevState.isShare
        }))
    }

    handleQuestion = () => {
        this.setState(prevState => ({
            isQuestion: !prevState.isQuestion
        }))
    }

    handleSosed = (finish) => {
        const sosed = cookies.get('sosed')

        if (sosed !== 0 && sosed !== undefined && !this.state.isUnfinish) {
            this.handleStep(7)
        }

        if (finish) {
            this.handlePurge(true)
        }

        this.setState(prevState => ({
            isSosed: !prevState.isSosed
        }))
    }

    handleContinue = (noUnfinish) => {
        const sosed = cookies.get('sosed')
        const re = /^[\d+-0()]{6,}$/g
        let filed2 = false

        if (sosed.name !== undefined && sosed.phone !== undefined) {

            if (sosed.name.length >= 3 && re.test(sosed.phone)) {
                filed2 = true
            }
        }

        let filed3 = false
        let step = 2

        if (sosed.title !== undefined && sosed.descr !== undefined)
            if (sosed.title.length >= 3 && sosed.descr.length >= 3) {
                filed3 = true
                step = 3
            }

        this.setState({
            isUnfinish: !noUnfinish,
            filledStep1: true,
            filledStep2: filed2,
            filledStep3: filed3,
            filledStep4: false,
            sosedStep: step,
            sosedName: sosed.name,
            sosedPhone: sosed.phone,
            sosedTitle: sosed.title,
            sosedDescr: sosed.descr,
            sosedStreets: [],
            findedStreets: [],
            isSuccessSearch: false,
            manualStreets: [],
            sosedResponse: 0,
        })
    }

    handlePurge = () => {
        const date = new Date(new Date().getTime())

        cookies.set(
            'sosed',
            0,
            {path: '/', expires: date, sameSite: 'none'}
        )
        this.setState({
            isUnfinish: true,
            filledStep1: false,
            filledStep2: false,
            filledStep3: false,
            filledStep4: false,
            sosedStep: 1,
            sosedName: '',
            sosedPhone: '',
            sosedTitle: '',
            sosedDescr: '',
            sosedStreets: [],
            findedStreets: [],
            isSuccessSearch: false,
            manualStreets: [],
            sosedResponse: 0,
            sosedError: null,
        })
    }

    handleStep = (step) => {
        const re = /^[\d+-0()]{6,}$/g
        const date = new Date(new Date().getTime() + 1800000)
        let sosed = {}

        switch (step) {
            case 1:
                if (this.state.sosedName.length >= 3 && re.test(this.state.sosedPhone))
                    this.setState({
                        filledStep2: true,
                    })
                else
                    this.setState({
                        filledStep2: false,
                    })

                this.setState({
                    sosedStep: step,
                })

                break

            case 2:
                if (this.state._search)
                    this.setState({
                        filledStep1: true,
                    })

                if (this.state.sosedTitle.length >= 3 && this.state.sosedDescr.length >= 3)
                    this.setState({
                        filledStep3: true,
                    })
                else
                    this.setState({
                        filledStep3: false,
                    })

                this.setState({
                    sosedStep: step,
                })

                break

            case 3:
                if (this.state.sosedStep === 2) {
                    const sosedName = document.getElementById('sosedName')

                    if (sosedName) {
                        if (sosedName.value === '' || sosedName.value.length < 3) {
                            sosedName.classList.add('error')
                            return
                        } else {
                            sosedName.classList.remove('error')
                        }
                    } else {
                        return
                    }

                    const sosedPhone = document.getElementById('sosedPhone')

                    if (sosedPhone) {

                        if (sosedPhone.value === '' || !re.test(sosedPhone.value)) {
                            sosedPhone.classList.add('error')
                            return
                        } else {
                            sosedPhone.classList.remove('error')
                        }
                    } else {
                        return
                    }

                    sosed.name = this.state.sosedName
                    sosed.phone = this.state.sosedPhone

                    cookies.set(
                        'sosed',
                        sosed,
                        {path: '/', expires: date, sameSite: 'none'}
                    )

                    this.setState({
                        filledStep2: true,
                        sosedStep: step,
                    })
                } else {
                    if (this.state.sosedName.length >= 3 && re.test(this.state.sosedPhone))
                        this.setState({
                            sosedStep: step,
                        })
                    else
                        this.setState({
                            sosedStep: 2,
                        })

                    this.setState({
                        filledStep1: true,
                    })
                }

                break

            case 4:
                if (this.state.sosedStep === 3) {
                    const sosedTitle = document.getElementById('sosedTitle')

                    if (sosedTitle) {
                        if (sosedTitle.value === '' || sosedTitle.value.length < 3) {
                            sosedTitle.classList.add('error')
                            return
                        } else {
                            sosedTitle.classList.remove('error')
                        }
                    } else {
                        return
                    }

                    const sosedDescr = document.getElementById('sosedDescr')

                    if (sosedDescr)
                        if (sosedDescr.value === '' || sosedDescr.value.length < 3) {
                            sosedDescr.classList.add('error')
                            return
                        } else
                            sosedDescr.classList.remove('error')
                    else
                        return

                    let cameraId = this.props.match.params.cameraId
                    let sosedStreets = []

                    this.props.items[cameraId].sosedi.map(
                        (sosed, i) =>
                            sosedStreets.push(
                                <li key={i}>
                                    {sosed}
                                    <span
                                        className="delete"
                                        onClick={() => this.handleDeleteStreet(i)}/>
                                </li>
                            )
                    )

                    sosed.name = this.state.sosedName
                    sosed.phone = this.state.sosedPhone
                    sosed.title = this.state.sosedTitle
                    sosed.descr = this.state.sosedDescr

                    cookies.set(
                        'sosed',
                        sosed,
                        {path: '/', expires: date, sameSite: 'none'}
                    )

                    this.setState({
                        filledStep3: true,
                        sosedStreets: sosedStreets,
                        sosedStep: step
                    })
                } else {
                    if (this.state.sosedTitle.length >= 3 && this.state.sosedDescr.length >= 3) {
                        let cameraId = this.props.match.params.cameraId
                        let sosedStreets = []

                        this.props.items[cameraId].sosedi.map(
                            (sosed, i) =>
                                sosedStreets.push(
                                    <li key={i}>
                                        {sosed}
                                        <span
                                            className="delete"
                                            onClick={() => this.handleDeleteStreet(i)}/>
                                    </li>
                                )
                        )

                        this.setState({
                            filledStep1: true,
                            filledStep2: true,
                            filledStep3: true,
                            sosedStreets: sosedStreets,
                            sosedStep: step
                        })
                    } else if (this.state.sosedName.length >= 3 && re.test(this.state.sosedPhone))
                        this.setState({
                            filledStep1: true,
                            filledStep2: true,
                            sosedStep: 3
                        })
                    else
                        this.setState({
                            filledStep1: true,
                            sosedStep: 2
                        })
                }

                break

            case 5:
                this.setState({
                    filledStep4: true,
                    sosedStep: step
                })

                break
            case 6:
                if (document.getElementById('finish').classList.contains('disabled'))
                    return false

                let adress = ''

                this.state.sosedStreets.map((street) => {
                    adress += street.props.children[0] + '|'
                })

                this.state.manualStreets.map((street) => {
                    adress += street.props.children[0] + '|'
                })

                const params = 'camera=' + this.props.match.params.cameraId
                    + '&name=' + encodeURIComponent(this.state.sosedName)
                    + '&phone=' + encodeURIComponent(this.state.sosedPhone)
                    + '&hapend=' + encodeURIComponent(this.state.sosedTitle)
                    + '&desc=' + encodeURIComponent(this.state.sosedDescr)
                    + '&adress=' + encodeURIComponent(adress)
                    + '&src=' + encodeURIComponent(this.state._search)

                axios.post(GET_SOSED_SEND_URL, params)
                    .then((response) => {
                        if (response.status !== 200) {
                            throw Error(response.statusText)
                        }
                        return response
                    })
                    .then((response) => {
                        if (response.data === 'error') {
                            this.setState({
                                sosedError: 'Произошла ошибка при добавлении обращения, пожалуйста, обновите страницу и попробуйте позже'
                            })
                        } else if (response.data === 'flood') {
                            this.setState({
                                sosedError: 'Вы слишком часто отправляете обращения, попробуйте позже!'
                            })
                        } else {
                            this.setState({
                                sosedError: null,
                                sosedResponse: response.data,
                                sosedStep: step,
                            })
                        }
                    })
                break

            case 7:
                this.setState({
                    sosedStep: step,
                })
                break
            default:
                return
        }
    }

    handleRules = () => {
        const checkbox = document.getElementById('rule')

        if (checkbox.checked === true) {
            document.getElementById('finish').classList.remove('disabled')
        }
    }

    handleSosedName = (value) => {
        let _value = value === undefined ? document.getElementById('sosedName').value : value
        const _this = this
        document.getElementById('sosedName').classList.remove('error')
        clearTimeout(window.timeout)

        window.timeout = setTimeout(
            () => {
                _this.setState({
                    sosedName: _value
                })
            },
            100
        )
    }

    handleSosedPhone = (value) => {
        let _value = value === undefined ? document.getElementById('sosedPhone').value : value
        const _this = this
        document.getElementById('sosedPhone').classList.remove('error')

        clearTimeout(window.timeout)
        window.timeout = setTimeout(
            () => {
                _this.setState({
                    sosedPhone: _value
                })
            },
            100
        )
    }

    handleSosedTitle = (value) => {
        const _value = value === undefined ? document.getElementById('sosedTitle').value : value
        const _this = this

        document.getElementById('sosedTitle').classList.remove('error')
        clearTimeout(window.timeout)

        window.timeout = setTimeout(
            () => {
                _this.setState({
                    sosedTitle: _value
                })

            },
            100
        )
    }

    handleSosedDescr = (value) => {
        const _value = value === undefined ? document.getElementById('sosedDescr').value : value
        const _this = this

        document.getElementById('sosedDescr').classList.remove('error')
        clearTimeout(window.timeout)

        window.timeout = setTimeout(
            () => {
                _this.setState({
                    sosedDescr: _value
                })
            },
            100
        )
    }

    handleSearchStreet = (street) => {
        let _street = street === undefined ? document.getElementById('sosedStreet').value : street
        const _this = this

        if (_street.length >= 3) {
            clearTimeout(window.timeout)

            window.timeout = setTimeout(
                () => {
                    axios.get(GET_SEARCH_STREET_URL + '&street=' + encodeURIComponent(_street))
                        .then((response) => {
                            if (response.status !== 200) {
                                throw Error(response.statusText)
                            }
                            return response
                        })
                        .then((response) => {
                            if (response.data !== -1)
                                _this.setState({
                                    findedStreets: response.data,
                                    isSuccessSearch: true
                                })
                        })
                },
                400
            )
        } else {
            _this.setState({
                isSuccessSearch: false
            })
        }
    }

    handleSelectStreet = street => {
        document.getElementById('sosedStreet').value = street
        this.setState({
            findedStreets: [],
            isSuccessSearch: false,
        })
    }

    handleSosedStreet = () => {
        const street = document.getElementById('sosedStreet')
        const home = document.getElementById('sosedHome')
        let _manualStreets = this.state.manualStreets

        if (street.value.length < 3) {
            street.classList.add('error')
            return false
        } else {
            street.classList.remove('error')
        }

        if (home.value.length < 1) {
            home.classList.add('error')
            return false
        } else {
            home.classList.remove('error')
        }

        const i = _manualStreets.length

        _manualStreets.push(<li key={i} id={'manual_' + i}>{street.value + ' ' + home.value}<span className="delete"
                                                                                                  onClick={() => this.handleDeleteManualStreet(i)}/>
        </li>)

        this.setState({
            manualStreets: _manualStreets
        })
    }

    handleDeleteManualStreet = (id) => {
        let filteredStreet = this.state.manualStreets.filter(street => street.key != id)

        this.setState({
            manualStreets: filteredStreet,
        })
    }

    handleDeleteStreet = (id) => {
        let filteredStreet = this.state.sosedStreets.filter(street => street.key != id)

        this.setState({
            sosedStreets: filteredStreet,
        })
    }

    handleShareGenerate = () => {
        const name = document.getElementById('share_video_name')
        name.classList.remove('error-share')

        const share_params = {
            "name": name.value,
            "camera_id": this.props.match.params.cameraId,
            "range": this.props.match.params.search,
            "camera_type": this.props.cameraType
        }

        let config = {
            body: JSON.stringify(share_params),
            method: 'POST'
        }

        mdApi.request(mdApi.apiUri() + "/camera_archive_share/", config)
            .then((response) => {
                if (!response.ok) {
                    name.classList.add('error-share')
                    return;
                }
                return response;
            })
            .then((response) => {
                response.json().then((parsedResponse) => {
                    this.setState({
                        share: SHARE_LINK + parsedResponse.key,
                    })
                })
            })
    }

    /**
     * Закрыть PopUp
     */
    handleClosePopUp = () => {
        this.setState({
            showAttentionPopUp: false
        })
    }

    /**
     * Получение архива видео
     *
     * @param camera
     */
    handleGetArchiveVideo = (camera) => {
        // превышен лимит в 3 часа - выводим модалку
        if (!this.state._download) {
            this.setState({
                showAttentionPopUp: true
            })

            return
        }

        let cameraId = camera.id
        let cameraName = camera.name

        let minuteRange = this.getTimeFromVal(this.state.values.min) + encodeURIComponent('-') + this.getTimeFromVal(this.state.values.max)
        let range = this.state._search

        let from = range.split('-')[0]
        let to = range.split('-')[1]

        let cameraNameFormatted = cameraName.replaceAll(",", " ")
        let filename = encodeURIComponent(cameraNameFormatted) + encodeURIComponent(' с Мой Дом [' + this.state.display + '] ') + range + '.flv'

        let cameraType = this.props.cameraType

        let requestDownloadUrlMethod;
        switch (cameraType) {
            case CAMERA_TYPE_PUBLIC:
                requestDownloadUrlMethod = (...args) => {
                    return mdApi.publicCamerasRequestDownloadUrl(...args)
                }
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                requestDownloadUrlMethod = (...args) => {
                    return mdApi.userCamerasRequestDownloadUrl(...args)
                }
                break;
            case CAMERA_TYPE_NEARBY_CAMERAS:
                requestDownloadUrlMethod = (...args) => {
                    return mdApi.nearbyCamerasRequestDownloadUrl(...args)
                }
                break;
            default:
                return;
        }

        let downloadUrl = requestDownloadUrlMethod(cameraId, from, to, filename)
            .then((data) => {
                let url = data.url
                window.location = url;
            })
            .catch(async response => {
                let code = response.status

                if (code === 402) {
                    this.requestBuyAccessModal(cameraId, cameraType, cameraName)
                } else if (code === 403) {
                    this.requestSignInModal()
                }
            })

        //
        // let src = this.state.camera.download
        // //let urlRangeWithVideoFormat = src.replace(id + '.m3u8', id + '-' + this.state._search + '.flv')
        // // let url = urlRangeWithVideoFormat + '?filename=' + encodeURIComponent(obj.name) + encodeURIComponent(' с Мой Дом [' + this.state.display + '] ')
        // //     + range + '.flv' + (obj.nopreroll !== undefined ? obj.nopreroll : '')
        // let url = src + `?from=${from}&to=${to}`
        //
        // window.open(url, '_blank')
        //
        // let params = 'cam_id=' + camera.id + '&range=' + this.state._search
        //
        // // отправляем запрос на логирование
        // axios.post(ARCHIVE_DOWNLOAD_LOG, params)
        //     .then((response) => {
        //         if (response.status !== 200) {
        //             throw Error(response.statusText)
        //         }
        //     })
    }

    /**
     * Установка значения "поделиться"
     *
     * @param value
     */
    setShareValue = (value) => {
        this.setState({
            'share': value,
            'copied': false
        })
    }

    /**
     * Отметка "Значение было скопировано"
     */
    setValueWasCopied = () => {
        this.setState({
            'copied': true
        })
    }

    render() {
        const id = this.props.match.params.cameraId
        let camera = this.state.camera

        if (camera === undefined) {
            return null
        }

        let cameraType = this.props.cameraType

        let isPublic = cameraType === CAMERA_TYPE_PUBLIC

        const calendar = archiveCalendar(
            camera.id,
            camera,
            this.getCurrentDefaultDate(),
            this.handleCalendar
        )

        return (
            <div>
                {
                    <ArchiveTags cameraTitle={camera.name}/>
                }
                {
                    BROWSER &&
                    <BrowserContainer
                        camera={this.state.camera}
                        cameraType={cameraType}
                        playlistUrl={this.state.playlistUrl}
                        attentionTextFirst={attentionTextFirst}
                        attentionTextSecond={attentionTextSecond}
                        calendar={calendar}
                        copied={this.state.copied}
                        display={this.state.display}
                        _download={this.state._download}
                        filledStep1={this.state.filledStep1}
                        filledStep2={this.state.filledStep2}
                        filledStep3={this.state.filledStep3}
                        filledStep4={this.state.filledStep4}
                        findedStreets={this.state.findedStreets}
                        id={id}
                        isPublic={isPublic}
                        isPubPath={this.props.match.path.match('pub')}
                        isShare={this.state.isShare}
                        isSosed={this.state.isSosed}
                        isSuccessSearch={this.state.isSuccessSearch}
                        isQuestion={this.state.isQuestion}
                        items={[camera]}
                        manualStreets={this.state.manualStreets}
                        maxValue={this.state.values.max}
                        minValue={this.state.values.min}
                        paramsSearch={this.props.match.params.search}
                        _search={this.state._search}
                        showAttentionPopUp={this.state.showAttentionPopUp}
                        share={this.state.share}
                        sosedDescr={this.state.sosedDescr}
                        sosedError={this.state.sosedError}
                        sosedName={this.state.sosedName}
                        sosedPhone={this.state.sosedPhone}
                        sosedResponse={this.state.sosedResponse}
                        sosedStep={this.state.sosedStep}
                        sosedStreets={this.state.sosedStreets}
                        sosedTitle={this.state.sosedTitle}
                        values={this.state.values}
                        isCitylinkRegion={this.props.isCitylinkRegion}
                        CalculateDownload={this.CalculateDownload}
                        changed={this.changed}
                        changedMax={this.changedMax}
                        changedMin={this.changedMin}
                        generateKey={this.generateKey}
                        getTimeFromVal={this.getTimeFromVal}
                        handleContinue={this.handleContinue}
                        handleClosePopUp={this.handleClosePopUp}
                        handlePurge={this.handlePurge}
                        handleRules={this.handleRules}
                        handleSearch={this.handleSearch}
                        handleSearchStreet={this.handleSearchStreet}
                        handleSelectStreet={this.handleSelectStreet}
                        handleShare={this.handleShare}
                        setShareValue={this.setShareValue}
                        handleShareGenerate={this.handleShareGenerate}
                        handleSosed={this.handleSosed}
                        handleSosedDescr={this.handleSosedDescr}
                        handleSosedName={this.handleSosedName}
                        handleSosedPhone={this.handleSosedPhone}
                        handleSosedStreet={this.handleSosedStreet}
                        handleSosedTitle={this.handleSosedTitle}
                        handleStep={this.handleStep}
                        handleQuestion={this.handleQuestion}
                        setValueWasCopied={this.setValueWasCopied}
                        handleGetArchiveVideo={this.handleGetArchiveVideo}
                    />
                }

                {
                    MOBILE &&
                    <MobileContainer
                        camera={this.state.camera}
                        cameraType={cameraType}
                        playlistUrl={this.state.playlistUrl}
                        attentionTextFirst={attentionTextFirst}
                        attentionTextSecond={attentionTextSecond}
                        calendar={calendar}
                        id={id}
                        isPublic={isPublic}
                        items={[camera]}
                        minValue={this.state.values.min}
                        maxValue={this.state.values.max}
                        _search={this.state._search}
                        showAttentionPopUp={this.state.showAttentionPopUp}
                        isCitylinkRegion={this.props.isCitylinkRegion}
                        changedMax={this.changedMax}
                        changedMin={this.changedMin}
                        getTimeFromVal={this.getTimeFromVal}
                        handleClosePopUp={this.handleClosePopUp}
                        handleSearch={this.handleSearch}
                        handleGetArchiveVideo={this.handleGetArchiveVideo}
                    />
                }
            </div>
        )
    }
}

Archive.propTypes = {
    fetchData: PropTypes.func.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isCitylinkRegion: PropTypes.bool.isRequired,
    cameraType: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    let stateRes = {}

    if (state.items.length !== 0) {
        stateRes = state.items
    }

    return {
        items: stateRes,
        hasErrored: state.itemsHasErrored,
        isLoading: state.itemsIsLoading,
        isCitylinkRegion: state.region === 'citylink',
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (url) => {
            dispatch(itemsFetchData(url))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Archive))
