import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {
    BROWSER,
    MOBILE,
    cityNameFormatted
} from '../helpers/constants/HeaderConstants';
import PublicCamerasCitiesList from './PublicCameras/PublicCamerasCitiesList';

import '../sass/Public.css';
import '../sass/Tools.css';
import PublicListTags from "./Header/Metatags/PublicListTags";
import {mdApi} from "../index";
import {AppContext, NOT_FOUND_PATH, PUBLIC_LIST_PATH, USER_CAMERAS_PATH} from "../routes";

class SpecialAccessList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cities: [],
            selectedCity: undefined,
            cameras: [],
            search: '',
            citySwitch: false,
            scroll: false,
            page: 1,
        }

        SpecialAccessList.contextType = AppContext
    }

    searchCameras = () => {
        let search = this.state.search
        if (search.length >= 3) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(() => {
                mdApi.specialAccessCamerasByCityKey(this.state.selectedCity.key, search, 1)
                    .then(cameras => {
                        this.setState({
                            page: 2,
                            cameras: cameras
                        })
                    })
            }, 400)
        } else {
            if (search.length === 0) {
                mdApi.specialAccessCamerasByCityKey(this.state.selectedCity.key, null, 1)
                    .then(cameras => {
                        this.setState({
                            page: 2,
                            cameras: cameras
                        })
                    })
            }
        }
    }

    handleSearch = (search) => {
        this.setState({
            search
        }, () => this.searchCameras())
    }

    handleCityList = () => {
        this.setState(prevState => ({
            citySwitch: !prevState.citySwitch
        }))
    }

    handleUpList = () => {
        window.scrollTo(0, 0)
    }

    handleButtonChange = (cityKey) => {
        let city = this.state.cities.find(city => city.key === cityKey)
        if (city === undefined) {
            return;
        }

        if (this.state.selectedCity === city) {
            return;
        }

        this.setState({
            citySwitch: false,
            selectedCity: city,
            cameras: [],
            page: 1
        }, () => {
            mdApi.specialAccessCamerasByCityKey(city.key, this.state.search, 1)
                .then(cameras => {
                    this.setState({
                        cameras: cameras,
                        page: this.state.page + 1
                    }, () => {
                        this.props.history.push(`${PUBLIC_LIST_PATH}/${city.public_key}`)
                    })
                })
        })
    }

    fetchHasSpecialAccess() {
        return mdApi.hasSpecialAccess();
    }

    componentWillUnmount() {
        clearTimeout(this.searchTimeout)
    }

    componentDidMount() {
        this.fetchHasSpecialAccess().then((response) => {
            if (response.status !== 200) {
                this.props.history.push(`/`)
            }
        })

        let publicCityKey = this.props.match.params.city

        mdApi.cities()
            .then(cities => {
                this.setState({cities}, () => {
                    let selectedCity = undefined

                    if (publicCityKey === undefined) {
                        selectedCity = cities[0] ?? undefined
                        if (selectedCity !== undefined) {
                            let path = `${PUBLIC_LIST_PATH}/${selectedCity.public_key}`;
                            if (this.props.location) {
                                path += this.props.location.search;
                            }
                            this.props.history.push(path);
                        }
                    } else {
                        selectedCity = cities.find(city => city.public_key === publicCityKey)
                    }

                    if (selectedCity !== undefined) {
                        this.setState({
                            selectedCity: selectedCity,
                            page: 1
                        }, () => {
                            mdApi.specialAccessCamerasByCityKey(selectedCity.key, this.state.search, this.state.page)
                                .then(cameras => {
                                    if (cameras.length > 0) {
                                        this.setState({
                                            cameras: cameras,
                                            page: this.state.page + 1
                                        })
                                    }
                                })
                        })
                    } else {
                        this.props.history.push(NOT_FOUND_PATH)
                    }
                })
            })

        let timer

        window.onscroll = () => {
            if (!document.getElementsByClassName('public-wrapper').length)
                return

            let scrollHeight, totalHeight
            scrollHeight = document.body.scrollHeight - 100
            totalHeight = window.scrollY + window.innerHeight

            if (totalHeight >= scrollHeight) {
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(() => {
                    mdApi.specialAccessCamerasByCityKey(this.state.selectedCity.key, this.state.search, this.state.page)
                        .then(cameras => {
                            if (cameras.length > 0) {
                                this.setState({
                                    cameras: this.state.cameras.concat(cameras),
                                    page: this.state.page + 1
                                })
                            }
                        })
                }, 1000)
            }

            if (window.scrollY > 500)
                this.setState({
                    'scroll': true,
                })
            else
                this.setState({
                    'scroll': false,
                })
        }
    }

    render() {
        let selectedCity = this.state.selectedCity

        if (selectedCity === undefined) {
            return null
        }

        let items = this.state.cameras.map(camera => {
            return <li className="items-grid__item" key={camera.id}>
                <Link className="items-grid__item_link" to={`/cameras/${camera.id}`}>
                    <img src={camera.img} alt={camera.name}/>
                    <span className="items-name">{camera.name}</span>
                </Link>
                <Link className="items-grid__item_arcLink" to={`/cameras/${camera.id}/archive`}>
                    Архив
                </Link>
                <Link className="items-grid__item_arcLink screen" to={`/cameras/${camera.id}/screen`}>
                    Снимки
                </Link>
            </li>
        });

        return (
            <div>
                <PublicListTags
                    cityUrl={selectedCity.key}
                    city1={cityNameFormatted(selectedCity.name)[0]}
                    city2={cityNameFormatted(selectedCity.name)[1]}
                />
                {
                    BROWSER &&
                    <div className="content-wrapper publist">
                        {
                            this.state.scroll &&
                            <div className="upper" onClick={this.handleUpList}/>
                        }
                        <div className="content-navigate publist">
                            <div className="content-navigate__city" onClick={this.handleCityList}>
                                {selectedCity.name}
                            </div>
                            {
                                this.state.citySwitch &&
                                <div className="content-navigate__hiddenList">
                                    <ul>
                                        <PublicCamerasCitiesList
                                            handleButtonChange={this.handleButtonChange}
                                        />
                                    </ul>
                                </div>
                            }
                            <div className="content-navigate__search">
                                <input
                                    id="search"
                                    onChange={({target: {value}}) => this.handleSearch(value)}
                                    value={this.state.search}
                                    autoComplete="off"
                                    placeholder="Искать камеру по названию или улице"
                                />
                                <button className="search" onClick={this.searchCameras}>Искать</button>
                            </div>
                            <div className="content-navigate__switch">
                                <NavLink to={`/spec/${selectedCity.public_key}`}
                                         isActive={() => {
                                             return false
                                         }}>
                                    На карте
                                </NavLink>
                                <NavLink to={`/speclist/${selectedCity.public_key}`}>Список</NavLink>
                            </div>
                        </div>
                        <div className="public-wrapper">
                            {
                                items.length !== 0 &&
                                <div>
                                    <ul className="items-grid">
                                        {items}
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    MOBILE &&
                    <div>
                        {
                            this.state.scroll &&
                            <div className="upper" onClick={this.handleUpList}/>
                        }
                        <div className="mobile-navigate publist">
                            <div className="mobile-navigate__wrap" onClick={this.handleCityList}>
                                <div className="mobile-navigate__city">
                                    {this.state.cityName}
                                </div>
                            </div>
                            {
                                this.state.citySwitch &&
                                <div className="mobile-navigate__hiddenList">
                                    <ul>
                                        <PublicCamerasCitiesList
                                            handleButtonChange={this.handleButtonChange}
                                        />
                                    </ul>
                                </div>
                            }
                            <div className="mobile-navigate__search">
                                <input
                                    id="search"
                                    onChange={({target: {value}}) => this.handleSearch(value)}
                                    autoComplete="off"
                                    placeholder="Поиск камеры по улице"/>
                            </div>
                        </div>
                        <div className="mobile-wrapper public-wrapper">
                            {
                                items.length !== 0 &&
                                <ul className="items-grid">
                                    {items}
                                </ul>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default SpecialAccessList;
