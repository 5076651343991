import React, {Component} from 'react'
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '../sass/VideoPlayer.css';
import cameraMockPreview from "../images/play.png"
import {mdApi} from "../index";

window.videojs = videojs;
require('videojs-hotkeys/build/videojs.hotkeys.min.js');

export default class VideoPlayer extends Component {
    componentWillUnmount() {
        const player = document.getElementById(this.state.id);

        videojs(player).dispose();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.source !== this.props.source;
    }

    constructor(props) {
        super(props);

        this.state = {
            isArchive: this.props.isArchive,
            autoplay: this.props.notAutoPlay ? false : true,
            id: this.props.customId ? this.props.customId : 'camera',
            cameraId: this.props.cameraId ? this.props.cameraId : null,
            cameraType: this.props.cameraType ? this.props.cameraType : null,
        }
    }

    onPlayVideo = () => {
        if (this.state.cameraId && this.state.cameraType) {
            mdApi.setCameraView(this.state.cameraId, 'web.' + this.state.cameraType);
        }
    }

    startVideo = video => {
        if (video) {
            const _isArchive = this.state.isArchive;
            const _onPlayVideoEv = this.onPlayVideo;

            videojs(video, {
                playbackRates: (_isArchive) ? [0.5, 1, 2, 5, 10, 16] : [],
                // withCredentials: true,
                // html5: {
                //     withCredentials: true,
                //     vhs: {
                //         withCredentials: true
                //     },
                //     hls: {
                //         withCredentials: true
                //     }
                // },
                // hls: {
                //     withCredentials: true
                // }
            }).ready(function () {
                this.hotkeys({
                    volumeStep: 0.1,
                    seekStep: 5,
                    enableModifiersForNumbers: false
                });

                if (!_isArchive)
                    document.getElementById('camera').focus();

                this.on ('play', function () {
                    _onPlayVideoEv();
                })
            });
        }
    }

    render() {
        let poster = this.props.poster;

        //оставлено для разработки
        if(process.env.NODE_ENV === 'development'){
            poster = cameraMockPreview
        }

        return (
            <div className="video-wrapper">
                <video id={this.state.id}
                       tabIndex="1"
                       ref={this.startVideo}
                       width={this.props.width}
                       height={this.props.height}
                       className={"video-js vjs-moidom-skin vjs-big-play-centered" + (this.props.audio ? '' : ' noAudio')}
                       autoPlay={this.state.autoplay} controls poster={poster}>

                    <source src={this.props.source} type={this.props.type}/>
                </video>
            </div>
        );
    }
}

VideoPlayer.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    audio: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};
